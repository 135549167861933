@import 'reset.css';

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700,900');

* {
  font-family: Lato, serif !important;
}

@import 'vars';

body {
  display: block;
  min-width: 100%;
  width: auto;
  background: #f6f6f6;
}

.hidden-mobile {
  display: none;
  @include desktop {
    display: block;
  }
}

.hidden-desktop {
  @include desktop {
    display: none;
  }
}

.wrapper {
  min-width: 100%;
  width: 100%;
  overflow: hidden;
}

.container {
  max-width: 500px;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 1px 42px 0;
  margin: -1px auto 0;

  @include desktop {
    min-width: auto;
    max-width: 1140px;
    padding: -1px 18px 0 18px;
  }

}

.btn-orcamento {
  display: block;
  width: 400px;
  height: 50px;
  background: #fff;
  border: 0;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  line-height: 50px;
  color: $primaryColor;
  font-size: 21px;
  font-weight: 900;
  outline: none;
}

.header {
  width: 100%;
  height: 1236px;
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: #000;

  &.chapas {
    background-image: url('../images/chapas/header_background_mobile.png');
  }

  &.barras {
    background-image: url('../images/barras/header_background_mobile.png');
  }

  &.tubos {
    background-image: url('../images/tubos/header_background_mobile.png');
  }

  &.tubosquadrados {
    background-image: url('../images/tubosquadrados/header_background_mobile.png');
  }

  &.tubosretangulares {
    background-image: url('../images/tubosretangulares/header_background_mobile.png');
  }

  @include desktop {
    height: 960px !important;
    padding-top: 1px;
    margin-top: -1px;
    //background-size: contain;

    &.chapas {
      background-image: url('../images/chapas/header_background.png');
    }

    &.barras {
      background-image: url('../images/barras/header_background.png');
    }

    &.tubos {
      background-image: url('../images/tubos/header_background.png');
    }

    &.tubosquadrados {
      background-image: url('../images/tubosquadrados/header_background.png');
    }

    &.tubosretangulares {
      background-image: url('../images/tubosretangulares/header_background.png');
    }

  }

  .logo {
    width: 166px;
    height: 118px;
    position: absolute;
    top: 53px;
    left: 50px;
    z-index: 1000;

    img {
      width: 100%;
    }

    &:after {
      content: '';
      width: 354px;
      height: 3px;
      display: block;
      background: #fff;
      position: absolute;
      top: 58px;
      left: 195px;
    }

  }

  .product-image {
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;

    &.chapas {
      width: 501px;
      height: 368px;
      top: 140px;
      left: -20px;
      background-image: url('../images/chapas/header_product.png');
    }

    &.barras {
      width: 494px;
      height: 330px;
      top: 140px;
      left: -20px;
      background-image: url('../images/barras/header_product.png');
    }

    &.tubos {
      width: 501px;
      height: 368px;
      top: 140px;
      left: -20px;
      background-image: url('../images/tubos/header_product.png');
    }

    &.tubosod {
      width: 501px;
      height: 368px;
      top: 140px;
      left: -20px;
      background-image: url('../images/tubosod/header_product.png');
    }

    &.tubosquadrados {
      width: 501px;
      height: 368px;
      top: 140px;
      left: -20px;
      background-image: url('../images/tubosquadrados/header_product.png');
    }

    &.tubosretangulares {
      width: 501px;
      height: 368px;
      top: 140px;
      left: -20px;
      background-image: url('../images/tubosretangulares/header_product.png');
    }

    @include desktop {

      &.chapas {
        width: 1182px;
        height: 727px;
        top: 190px;
        left: -480px;
      }

      &.barras {
        width: 932px;
        height: 624px;
        top: 210px;
        left: -280px;
      }

      &.tubos {
        width: 1182px;
        height: 764px;
        top: 150px;
        left: -390px;
      }

      &.tubosod {
        width: 1182px;
        height: 764px;
        top: 150px;
        left: -390px;
      }

      &.tubosquadrados {
        width: 1182px;
        height: 764px;
        top: 150px;
        left: -390px;
      }

      &.tubosretangulares {
        width: 1182px;
        height: 764px;
        top: 150px;
        left: -390px;
      }

    }
  }

  .wrapper-box {
    width: 100%;

    @include desktop {
      width: 400px;
      float: right;
    }
  }

  .product-info-box {
    width: 400px;
    color: #fff;
    text-align: justify;
    font-size: 21px;
    line-height: 32px;
    margin: 495px 0 0 0;
    padding-bottom: 35px;

    .box-icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 20px;
      padding-bottom: 20px;

      img {
        align-self: flex-start;
      }
    }

    &.cantoneiras {
      margin: 350px 0 0 0;

      @include desktop {
        margin: 270px 0 0 0;
      }
    }

    &.chapa-grossa {
      margin: 430px 0 0 0;

      @include desktop {
        margin: 250px 0 0 0;
      }
    }

    &.fita-tiras {
      margin: 530px 0 0 0;

      @include desktop {
        margin: 265px 0 0 0;
      }
    }

    &.tubos-com-costura {
      margin: 450px 0 0 0;

      @include desktop {
        margin: 240px 0 0 0;
      }
    }

    &.tubos-sem-costura {
      margin: 450px 0 0 0;

      @include desktop {
        font-size: 19px;
        margin: 210px 0 0 0;
      }
    }

    @include desktop {
      font-size: 19px;
      line-height: 27px;
      margin-top: 256px;

      .wrapper-btn-orcamento {
        display: block;
        //width: 400px;
        height: 60px;
      }

      .btn-orcamento {
        //width: 300px;
        height: 60px;
        line-height: 60px;
        float: right;
        font-size: 18px;
        letter-spacing: 1px;
      }

    }

    .wrapper-btn-orcamento {
      width: 100%;
    }

    h1 {
      font-weight: 900;
      font-size: 96px;
      letter-spacing: 3px;
      line-height: 60px;
      text-transform: uppercase;
      margin-bottom: 10px;
      //text-align: justify;

      &.barras {
        font-size: 96px;
      }

      &.blanques {
        font-size: 80px;
      }

      &.tubosquadrados {
        font-size: 84px;
        padding-top: 50px;

        span {
          @include desktop {
            font-size: 36px;
          }
        }

        @include desktop {
          padding-top: 0;
        }
      }

      &.tubosretangulares {
        font-size: 84px;
        padding-top: 50px;

        span {
          @include desktop {
            font-size: 32px;
          }
        }

        @include desktop {
          padding-top: 0;
        }
      }

      &.cantoneiras {
        font-size: 51px;
      }

      &.chapa-grossa {
        font-size: 93px;

        .second {
          font-size: 82px;
          padding-top: 20px;
          display: inline-block;
        }

        .last {
          line-height: 30px !important;
        }

      }

      &.tubos-com-costura {
        font-size: 63px;

        .second {
          font-size: 80px;
          padding-top: 14px;
          display: inline-block;
        }

        .last {
          line-height: 30px !important;
        }

      }

      &.tubos-sem-costura {
        font-size: 64px;

        .second {
          font-size: 80px;
          padding-top: 14px;
          display: inline-block;
        }

        .last {
          line-height: 30px !important;
        }

      }

      &.fita-tiras {
        font-size: 66px;
      }

      span {
        margin: 5px 0 0 0;
        font-size: 42px;
        letter-spacing: 0;
      }

    }

    p {
      .btn-orcamento {
        margin: 20px 0 0 0;
      }
    }

  }

  .contact-box {
    width: 400px;
    color: #fff;
    text-align: justify;
    line-height: 32px;

    @include desktop {
      padding-top: 20px;
    }

    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      p {
        font-size: 25px;
        width: 200px;
        flex-direction: column;
        text-align: left;
        padding-top: 15px;

        &:first-child {
          font-size: 25px;
          width: 400px;
          padding-top: 8px;
        }

        @include desktop {
          padding-top: 0;
          line-height: 30px;
        }

        &:nth-child(2) {
          @include desktop {
            font-size: 22px;
            height: 65px;
            border-right: 1px solid #fff;
            margin-top: 18px;
          }
        }

        &:nth-child(3) {
          padding-left: 25px;
          @include desktop {
            font-size: 22px;
            margin-top: 18px;
          }
        }

      }

    }

    .title {
      font-size: 33px;
      font-weight: bold;
      border-bottom: 2px solid #fff;
      padding: 0 0 10px 0;
    }

  }

}

.nav-toggle {
  width: 57px;
  height: 48px;
  border: 0;
  position: absolute;
  left: 45px;
  top: 45px;
  background: url('../images/btn_menu.png') no-repeat;
  outline: none;
  cursor: pointer;
}


.menu {
  width: 100%;
  background: #fff;
  position: absolute;
  top: 130px;
  left: 0;
  box-shadow: 1px 1px 15px rgba(0,0,0, 0.5);
  height: 0;
  transition: height .3s ease-in-out;
  overflow: hidden;
  z-index: 1000;
  padding: 0;

  @include desktop {
    height: auto;
    overflow: hidden;
    background: none;
    box-shadow: none;
    top: 100px;
  }

  .container {
    width: 100%;
    max-width: none;
    padding: 0;

    @include desktop {
      min-width: auto;
      max-width: 1140px;
      padding: -1px 18px 0 18px;
    }
  }

  &.open {
    height: 256px;
  }

  ul {
    width: 100%;
    list-style: none;
    padding: 30px 0;

    @include desktop {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      float: right;
      margin-right: -10px;
    }

    li {
      width: 100%;
      height: 50px;
      text-align: center;

      @include desktop {
        height: auto;
        width: auto;
        border-right: 2px solid #fff;

        &:last-child {
          border: none;
        }

      }

      &:hover {
        background: $primaryColor;

        a {
          color: #fff;
        }

        @include desktop {
          background: none;
        }

      }

      a {
        width: 100%;
        height: 100%;
        display: block;
        font-size: 20px;
        color: $primaryColor;
        line-height: 49px;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;

        @include desktop {
          color: #fff;
          text-transform: none;
          line-height: normal;
          padding: 0 15px;
          font-size: 21px;
        }
      }

    }

  }

}

.sections {
  width: 100%;
}

.about {
  position: relative;
  padding-bottom: 40px;

  .container .box {
    @include desktop {
      width: 432px;
    }
  }

  .container {
    text-align: center;
  }

  .logo {
    padding: 70px 0 40px 0;
  }

  .text {
    text-align: justify;
    font-size: 24px;
    line-height: 44px;
    color: $textColor;

    @include desktop {
      font-size: 18px;
      line-height: 32px;
    }
  }

  .about-img-desktop {
    display: none;
    width: 959px;
    height: 987px;
    background: url('../images/about_image_desktop.png') no-repeat center;
    position: absolute;
    top: -92px;
    right: -390px;
    z-index: -1;

    @include desktop {
      display: block;
    }
  }

  .about-img {
    width: 100%;
    height: 444px;
    background: url('../images/about_image.png') no-repeat center;
    margin-top: 40px;
  }

  .quality-certification {

    width: 100%;

    @include desktop {
      margin: 47px 0 0 0;
    }

    a {
      display: block;

      img {
        width: 100%;
        max-width: 259px;
      }

      margin: 65px 0 50px 0;

      @include desktop {
        width: 160px;
        align-self: center;
        margin: -50px 0 0 0;
      }

    }

    .box {

      @include desktop {
        display: flex;
        justify-content: space-between;
      }

    }

    .text {

      @include desktop {
        width: 230px;
        justify-self: right;
      }

    }

  }


  .our-units {

    margin-top: 90px;

    @include desktop {
      margin-top: 110px;
    }

    h3 {

      color: $primaryColor;
      font-size: 26px;
      margin-bottom: 87px;

      @include desktop {
        font-size: 36px;
        margin-bottom: 55px;
      }

    }

    .units {

      @include desktop {
        display: flex;
        justify-content: space-between;
      }

      .unit {

        @include desktop {
          padding-right: 10px;
        }

        .title {

          color: $primaryColor;
          font-size: 32px;
          font-weight: bold;
          border-bottom: 2px solid #bdbdbd;
          padding-bottom: 22px;
          margin-bottom: 22px;
          padding-left: 30px;
          position: relative;
          text-align: left;

          @include desktop {
            font-size: 18px;
            padding-left: 15px;
            padding-bottom: 18px;
            margin-bottom: 25px;
          }

          &::before {
            content: '';
            width: 22px;
            height: 27px;
            display: block;
            position: absolute;
            left: 0px;
            top: 3px;
            background: url('../images/marker.png');

            @include desktop {
              width: 11px;
              height: 15px;
              top: 1px;
              background: url('../images/marker_desktop.png');
            }

          }

        }

        .address {
          color: #878787;
          font-size: 20px;
          letter-spacing: 1px;
          line-height: 37px;
          text-align: left;
          margin-bottom: 50px;

          @include desktop {
            font-size: 13px;
            letter-spacing: normal;
            line-height: 25px;
          }

        }
      }
    }
  }
}

.contact {
  width: 100%;
  height: 1210px;
  background-size: cover;
  color: #fff;
  padding-top: 60px;
  text-align: center;

  &.chapas {
    background: #000 url('../images/chapas/contact_background_mobile.png') no-repeat center;
  }

  &.barras {
    background: #000 url('../images/barras/contact_background_mobile.png') no-repeat center;
  }

  &.tubos {
    background: #000 url('../images/tubos/contact_background_mobile.png') no-repeat center;
  }

  &.tubosquadrados {
    background: #000 url('../images/tubosquadrados/contact_background_mobile.png') no-repeat center;
  }

  &.tubosretangulares {
    background: #000 url('../images/tubosretangulares/contact_background_mobile.png') no-repeat center;
  }

  @include desktop {
    height: 1082px;
    padding-top: 155px;

    &.chapas {
      background: #000 url('../images/chapas/contact_background.png') no-repeat center;
    }

    &.barras {
      background: #000 url('../images/barras/contact_background.png') no-repeat center;
    }

    &.tubos {
      background: #000 url('../images/tubos/contact_background.png') no-repeat center;
    }

    &.tubosquadrados {
      background: #000 url('../images/tubosquadrados/contact_background.png') no-repeat center;
    }

    &.tubosretangulares {
      background: #000 url('../images/tubosretangulares/contact_background.png') no-repeat center;
    }

  }

  .box {

    @include desktop {
      width: 642px;
      margin: 0 auto;
    }

  }

  .title-wrapper {
    width: 100%;
    text-align: center;
  }

  h3 {
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    letter-spacing: 2px;
    padding-bottom: 25px;
    border-bottom: 2px solid #fff;
    margin-bottom: 28px;

    @include desktop {
      width: 260px;
      border-bottom: 3px solid #fff;
      margin: 0 auto 30px;
    }
  }

  .description {
    text-align: justify;
    font-size: 25px;
    letter-spacing: 1px;
    line-height: 31px;
    padding-bottom: 40px;

    @include desktop {
      font-size: 21px;
    }
  }

}

.contact-form-wrapper {

  @include desktop {
    width: 394px;
    margin: 0 auto;
  }

  .input-wrapper {
    width: 100%;
    margin: 10px 0;

    @include desktop {
      margin: 15px 0;
    }

    input {
      width: 100%;
      height: 66px;
      border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 20px;
      color: $textColor;
      outline: none;
      padding: 15px;

      @include placeholder(#969696);
      @include desktop {
        font-size: 14px;
        padding: 10px;
        height: 40px;
      }
    }

    textarea {
      width: 100%;
      outline: none;
      resize: none;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 15px;
      font-size: 20px;
      @include placeholder(#969696);
      @include desktop {
        font-size: 14px;
        padding: 10px;
      }
    }

  }

  .btn-submit {
    width: 100%;
    display: block;
    height: 66px;
    color: #fff;
    font-size: 27px;
    font-weight: bold;
    border: 0;
    border-radius: 5px;
    background: linear-gradient(to right, #db5f5f, #b94341);
    outline: none;
    cursor: pointer;

    @include desktop {
      width: 192px;
      height: 56px;
      font-size: 20px;
      margin: 0 auto;
    }
  }

  .two-cols {
    @include desktop {
      display: flex;
      justify-content: space-between;

      > .input-wrapper {
        width: 48%;
        margin: 0;
      }
    }
  }

}

.footer {
  width: 100%;
  height: 625px;
  position: relative;
  background-image: linear-gradient(#dc5f5d, #b84242);

  @include desktop {
    height: 492px;
  }

  .reminder {
    color: #fff;
    text-align: center;

    .title {
      font-size: 25px;
      letter-spacing: 2px;
      font-weight: 300;
      padding-top: 47px;

      @include desktop {
        font-size: 22px;
        padding-top: 55px;
        font-weight: normal;
      }

    }

    .product-name {
      font-size: 58px;
      font-weight: 900;
      line-height: 70px;
      letter-spacing: 2px;
      padding-top: 35px;
      position: relative;
      text-transform: uppercase;

      &.tubos-com-costura, &.tubos-sem-costura {
        font-size: 46px;
        line-height: 48px;
        @include desktop {
          font-size: 50px;
        }
      }

      @include desktop {
        font-size: 60px;
        font-weight: bold;

        &:before {
          content: '';
          width: 71px;
          height: 2px;
          background: #fff;
          position: absolute;
          top: 71px;
          margin-left: -92px;
        }

        &:after {
          content: '';
          width: 71px;
          height: 2px;
          background: #fff;
          position: absolute;
          top: 71px;
          margin-left: 20px;
        }

      }

    }

    .text {
      text-align: justify;
      text-transform: uppercase;
      font-size: 21px;
      line-height: 30px;
      font-weight: 300;
      padding: 30px 0 0 0;

      @include desktop {
        text-align: center;
        font-weight: normal;
      }
    }

    .btn-orcamento {
      margin: 30px auto;

      @include desktop {
        width: 330px;
        height: 54px;
        line-height: 54px;
        margin: 60px auto;
      }
    }

  }

  .copyright {
    width: 100%;
    color: #fff;
    font-size: 12px;
    text-align: center;
    position: absolute;
    bottom: 52px;

    @include desktop {
      bottom: 32px;
    }

    .powered-by {
      width: 143px;
      display: inline-block;
      position: relative;
      text-align: left;

      .icon {
        display: inline-block;
        width: 50px;
        height: 10px;
        top: 3px;
        right: 0;
        margin-right: -5px;
        position: absolute;
        background: url('../images/logo_newmove.png') no-repeat;
        background-size: contain;
        cursor: pointer;
      }

    }

  }

}